import EsmLogo from "../media/EsmLogo.webp";
import PierreViret from "../media/pierreviret.webp";
import Lausanne from "../media/lausanne.webp";



const education = () => {
  return (
    <div>
        <h1 className='titreCert' id="education">Education</h1>
        <ul className="containerEdu">
            <li className="education">
                <img loading="lazy" className="logoEdu" src={EsmLogo} alt="ESM logo" />
                <h4 className="eduTitle">Bachelor International Management</h4>
                <p className="eduText">School of Management and Communication</p>
                <p className="datePlace">Geneva <span> 2011- 2013</span></p>
            </li>
            <li className="education">
                <img loading="lazy" className="logoEdu"  src={PierreViret} alt="collège pierre viret logo" />
                <h4 className="eduTitle">Diploma <br /> in Commerce</h4>
                <p className="eduText">Pierre Viret College</p>
                <br />
                <p className="datePlace">Lausanne <span> 2010- 2011</span></p>
            </li>
            <li className="education">
                <img loading="lazy" className="logoEduLs" src={Lausanne} alt="collège de villamont logo" />
                <h4 className="eduTitle">High School Certificate</h4>
                <p className="eduText">Villamont College</p>
                <br />
                <p className="datePlace">Lausanne <span> 2009</span></p>
            </li>
        </ul>
    </div>
  )
}

export default education