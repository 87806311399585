import './App.css';
import { useState } from "react";
import React from 'react';
import Header from "./component/header";
import News from "./component/news";
import Expecomp from './component/expecomp';
import Certification from './component/Certification';
import Education from "./component/education";
import Footer from './component/footer';

const App = () => {
  const [loading, setLoading] = useState(true);
  const spinner = document.getElementById("spinner");

  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
      setLoading(false);
    }, 3000);
  }

  return (
    !loading && (
      <>
        <Header />
        <News />
        <Expecomp />
        <Certification />
        <Education />
        <Footer />
      </>
    )
  )
}

export default App
