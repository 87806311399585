const footer = () => {
    return (
        <div className='footerContainer'>
                <h1 className='titleFooter' id="contact">Find me here !</h1>
                <div className='contactContainer'>
                    <a style={{ color: "inherit", textDecoration: "inherit" }} href="mailto:audrey.f.wirth@gmail.com"><p>audrey.f.wirth@gmail.com</p></a>
                   {//<a style={{ color: "inherit", textDecoration: "inherit" }} href="tel:+41774028895"> <p className='number'>+41 77 402 88 95</p></a>
}
                </div>
                <div className='iconContainer d-flex justify-content-center justify-content-lg-between p-4'>

                    <a href="https://www.webryn.com" className='me-4 text-reset' target="_blank" rel="noopener noreferrer"><i class="bi bi-browser-chrome"></i>
                    </a>
                    <a href="https://github.com/AudreyFW" className='me-4 text-reset' target="_blank" rel="noopener noreferrer"><i className="bi bi-github"></i>
                    </a>
                    <a href="https://www.linkedin.com/in/a-wirth" className='me-4 text-reset' target="_blank" rel="noopener noreferrer"><i className="bi bi-linkedin"></i>
                    </a>
                </div>
        </div>
    )
}

export default footer