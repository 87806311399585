import htmlPic from "../media/html-5.webp";
import cssPic from "../media/css-3.webp";
import javascriptPic from "../media/js.webp";
import reactPic from "../media/react.webp";
import sqlPic from "../media/SQL.webp";
import mongoDBPic from "../media/mongodb.webp";
import postgreSQLPic from "../media/postgresql.webp";
import phpPic from "../media/php.webp";
import figmaPic from "../media/figma.webp";
import vidLogo from "../media/vidLogo.webp";
import webrynLogo from "../media/webrynLogo.webp";
import mcdoLogo from "../media/mcdo.webp";

const expecomp = () => {
    return (
        <div className="row">
            <div className="colonneComp col-4" >
                <h1 className='titreComp' id="competences">Skills</h1>
                <div className='row'>
                    <ul className='compList'>
                        <li className='compContainer'>
                            <img loading="lazy" src={htmlPic} alt="logo html" />
                            <p>Html</p>
                        </li>
                        <li className='compContainer'>
                            <img loading="lazy" src={cssPic} alt="css logo" />
                            <p>
                                CSS
                            </p>
                        </li>
                        <li className='compContainer'>
                            <img loading="lazy"  src={javascriptPic} alt="javascript logo" />
                            <p>
                                Javascript
                            </p>
                        </li>
                        <li className='compContainer'>
                            <img  loading="lazy" src={reactPic} alt="react logo" />
                            <p>React</p>
                        </li>
                        <li className='compContainer'>
                            <img loading="lazy" src={sqlPic} alt="sql logo" />
                            <p>SQL</p>
                        </li>
                        <li className='compContainer'>
                            <img loading="lazy" src={mongoDBPic} alt="mongoDb logo" />
                            <p>MongoDB</p>
                        </li>
                        <li className='compContainer'>
                            <img loading="lazy" src={postgreSQLPic} alt="postgresql logo" />
                            <p>PostgreSQL</p>
                        </li>
                        <li className='compContainer'>
                            <img loading="lazy" src={phpPic} alt="php logo" />
                            <p>PHP</p>
                        </li>
                        <li className='compContainer'>
                            <img loading="lazy" src={figmaPic} alt="figma logo" />
                            <p>Figma</p>
                        </li>
                    </ul>

                </div>
            </div>
            <div className="col">
                <h1 className='titreComp' id="exper">Experiences</h1>
                <ul className='listExp' style={{backgroundColor:"rgba(158, 140, 96, 0.07)",borderRadius:"20px", padding: "7px"}}>
                    <li className='expeCont'>
                        <p className='dateExp'>Oct. 2023 - Present <i class="bi bi-geo-alt-fill"></i> St-Sulpice, Switzerland</p>
                        <img  loading="lazy" className='expLogoSm' src={vidLogo} alt="VID logo" />
                        <p className='titleExp'>Web Developer Volunteer</p>
                        <p className='sstitExp'>VID Association</p>
                        <p className='textExp'>
                            Volunteer web developer for an association, I activery contribute by proposing innovative desighs for 
                            their website. Currently, I am collaborating to modernize their online presence. 
                        </p>
                    </li>
                    <li className='expeCont'>
                        <p className='dateExp'>Aug. 2023 - Present  <i class="bi bi-geo-alt-fill"></i> Lucens, Switzerland</p>
                    <img loading="lazy" className='expLogo' src={webrynLogo} alt="webryn logo" />
                        <p className='titleExp'>Independant Wed Developer</p>
                        <p className='sstitExp'>Webryn</p>
                        <p className='textExp'>
                        As an independent web developer, I work with various clients to create customized web solutions, offering design and
                        website development services.
                        </p>
                    </li>
                    <li className='expeCont'>
                        <p className='dateExp'>Dec. 2014 - Nov. 2021  <i class="bi bi-geo-alt-fill"></i> Lausanne, Switzerland</p>
                    <img loading="lazy" className='expLogoSm' src={mcdoLogo} alt="mcdonald's logo" />
                        <p className='titleExp'>Instructor and Guest Experience Leader</p>
                        <p className='sstitExp'>McDondald's Restaurant</p>
                        <p className='textExp'>
                        In my role as a leader, I have maintained procedures and fostered a positive team culture. I have also 
                        trained and supervised staff to ensure quality customer service.
                        </p>
                    </li>

                </ul>
            </div>
        </div>
    )
}

export default expecomp