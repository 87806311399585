import React from 'react';
import vid from "../media/vid.webp";
import pokemark from "../media/pokemark.png"
const news = () => {
    return (
        <div className='news'>
            <h1 className='titleNews' id="projets">Recent projects</h1>
            <div className='containerNews'>
            <div className="containerCard">
                    <section className="mx-auto my-5" style={{ maxWidth: "23rem" }}>
                        <div className="card">
                            <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                                <img loading="lazy" src={pokemark} alt="pokémark" className="img-fluid rounded-top" />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title font-weight-bold">Pokémark E-Commerce</h5>
                                <p className="mb-2"></p>
                                <p className="card-text">
                                    An e-commerce platform for the sale of second-hand Pokémon cards.
                                </p>
                                <hr className="my-4" />
                                <h5 className="font-weight-light">Creative Tools</h5>
                                <ul className="listOutils list-unstyled list-inline justify-content-between">
                                    <li className="outils list-inline-item me-0 rounded p-1">
                                        <div className="chip me-0">Node.js</div>
                                    </li>
                                    
                                    <li className="outils list-inline-item me-0 rounded p-1">
                                        <div className="chip me-0">React</div>
                                    </li>
                                    <li className="outils list-inline-item me-0 rounded p-1">
                                        <div className="chip me-0">MongoDB</div>
                                    </li>
                                    <li className="outils me-0 list-inline-item rounded p-1">
                                        <div className="chip me-0">Stripe</div>
                                    </li>
                                    <li className="outils list-inline-item me-0 rounded p-1">
                                        <div className="chip me-0">Heroku</div>
                                    </li>
                                </ul>
                                <a href="https://www.pokemark.ch" target="_blank" rel="noopener noreferrer" className="btnNews" >Explore</a>
                            </div>
                        </div>

                    </section>
                </div>
               
                <div className="containerCard">
                    <section className="mx-auto my-5" style={{ maxWidth: "23rem" }}>
                        <div className="card">
                            <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                                <img alt="VID logo" loading="lazy" src={vid} className="img-fluid rounded-top" />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title font-weight-bold">Figma Design</h5>
                                <p className="mb-2"></p>
                                <p className="card-text">
                                    Find one of my creations here for the VID association.
                                </p>
                                <hr className="my-4" />
                                <h5 className="font-weight-light">Creative Tools</h5>
                                <ul className="listOutils list-unstyled list-inline justify-content-between">
                                    <li className="outils list-inline-item me-0 rounded p-1">
                                        <div className="chip  me-0">Figma</div>
                                    </li>
                                    <li className="outils list-inline-item me-0 rounded p-1">
                                        <div className="chip me-0">WebAccappella</div>
                                    </li>
                                    <li className="outils me-0 rounded p-1">
                                        <div className="chip me-0">Abode express</div>
                                    </li>
                                </ul>
                                <a href="https://www.figma.com/file/QijIRz90ifvjTX7Eka9g5K/VID?type=design&node-id=0%3A1&mode=design&t=YlP98A5WKlqIXt02-1" target="_blank" rel="noopener noreferrer" className="btnNews">Figma link</a>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>

    )
}

export default news