import hackerLogo from "../media/hackerX.webp"
import codeLogo from "../media/codeca.webp";

const Certification = () => {
    return (
        <div>
            <h1 className='titreCert'>Certifications</h1>
            <ul className='certcontainer'>
                <li className='certification'>
                    <img className="certPic" src={hackerLogo} alt="amazon web services logo" loading="lazy" />
                    <p className="certText">Institute of Ethical Hacking<span className="certDate">Dec. 2023 - Present</span></p>
                    <h4 className="certTitle">Certificate of Ethical Hacking</h4>
                </li>
                <li className='certification'>
                    <img loading="lazy" className="certPic" src={codeLogo} alt="codecademy logo" />
                    <p className="certText">Codecademy <span>Apr. 2023 - May 2023</span></p>
                    <h4 className="certTitle">Intermediare PHP</h4>
                </li>
                <li className='certification'>
                    <img loading="lazy" className="certPic" src={codeLogo} alt="codecademy logo" />
                    <p className="certText">Codecademy <span>Jan. 2022 - Nov. 2022</span></p>
                    <h4 className="certTitle">Full Stack Engineer</h4>
                </li>
            </ul>
        </div>
    )
}

export default Certification