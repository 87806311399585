import profilPic from "../media/profil.webp";

const header = () => {
  return (
    
    <div
    className='containerHeader'
>
        <div className="headerMarge row">
            <div className='picHeader col-lg'>
                <img loading="lazy" src={profilPic} alt="profil" style={{ display: "block", borderRadius:"100%", marginLeft:"auto", marginRight:"auto" }} />
            </div>
            <div className="col-lg">
                <div className='d-flex justify-content-right align-items-start h-300'>
                    <div>
                        <h4 className='sousTitre mb-1 mt-5 text-secondary'>Web developer</h4>
                        <h1 className='mb-3 font-weight-bold display-1'>Audrey Wirth</h1>
                        <p className='textHeader'>Passionate web developer skilled in multiple programming languages.</p>
                        <a className='buttonHeader' href='mailto:audrey.f.wirth@gmail.com'  role='button'>
                            Contact me
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default header

